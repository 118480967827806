#root {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    /* background: linear-gradient(#fff, #999); */
    background: white;
  }
  
  * {
    user-select: none;
  }
  
  #root>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .app {
    overflow: hidden;
  }
  
  .app>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .row {
    flex-direction: row !important;
  }
  
  .row>* {
    margin: 5px;
  }
  
  h1 {
   
    text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  }
  
  h2 {
    color: #fff;
  }
  
  .swipe {
    position: absolute;
  }

  .swipeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 260px;
    height: 300px;
    color: black
}

  
  .card {
    position: relative;
    background-color: #fff;
    width: 80vw;
    max-width: 260px;
    height: 300px;
    box-shadow: 0px 0px 10px 0px rgba(99, 99, 99, 0.3);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .cardContent {
    width: 100%;
    height: 100%;
  }
  
  .swipe:last-of-type {
  
  }
  
  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }
  
  .infoText {
    width: 100%;
    justify-content: center;
    display: flex;
    color: black;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  .buttons {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the buttons horizontally */
}

@media (max-width: 625px) {
    .buttons {
        flex-direction: column;
        align-items: center; /* Center the buttons in the column direction */
    }
}

  
  .buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 10px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
  }
  
  .buttons button:hover {
    transform: scale(1.05);
  }
  
  @keyframes popup {
    0%   { transform: scale(1,1) }
    10%  { transform: scale(1.1,1.1) }
    30%  { transform: scale(.9,.9) }
    50%  { transform: scale(1,1) }
    57%  { transform: scale(1,1) }
    64%  { transform: scale(1,1) }
    100% { transform: scale(1,1) }
  }
