.container {
    /* background: lightblue; */
    cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39, auto;
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
  }
  
  .deck {
    position: absolute;
    width: 300px;
    height: 200px;
    will-change: transform;
    display: flex;
    padding-right: 35px;
    align-items: center;
    justify-content: center;
    touch-action: none;
  }
  
  .deck > div {
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 45vh;
    max-width: 150px;
    height: 85vh;
    max-height: 285px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 50px -10px rgba(170, 170, 182, 0.4);

  }
  